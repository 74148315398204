import React from "react"
import styled from "styled-components"

import { Img, PageSectionWrap, PageTitle } from "../objects"
import FadeUp from "../fade-in-up"
import Button from "../button"

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-left: 12.309vw;
  width: 47.706vw;

  li {
    width: 50%;
    position: relative;
    padding: 1.25rem 0 2rem;
    margin-bottom: 2rem;
    padding-right: 1rem;

    p {
      position: relative;
      font-size: 0.825rem;

      &:before {
        content: "";
        position: absolute;
        top: -2.6rem;
        height: 1px;
        width: calc(100% - 1.5rem);
        background: ${props => props.theme.cream};
        opacity: 0.3;
      }
    }

    span {
      display: block;
      padding-bottom: 0.35rem;
    }

    i {
      position: absolute;
      right: 1.5rem;
      top: -2rem;
      font-size: 0.75rem;
    }
  }

  .portrait & {
    margin-left: 10%;
    width: 80%;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-left: 0;
    display: block;

    li {
      width: 100%;

      span {
        padding-top: 1rem;
      }
    }
  }
`

const Image = styled.figure`
  position: absolute;
  width: 19.394vw;
  height: 21.217vw;
  top: -11.676vw;
  left: 0;
  background: ${props => props.theme.gold};

  @media (max-width: 767px) {
    width: 75%;
    height: 50vw;
    position: relative;
    z-index: 1;
    top: 0;

    &:after {
      content: "";
      position: absolute;
      left: -10vw;
      background: ${props => props.theme.black};
      height: 100%;
      width: 100%;
      top: 20%;
      z-index: -1;
    }
  }
`

const Actions = styled.div`
  margin-left: 12.309vw;
  display: flex;
  padding-top: 4vw;

  > div {
    flex: 1;
  }

  @media (max-width: 767px) {
    margin-left: 0;
    width: 100%;

    > div {
      width: 50%;
    }
  }
`

export default ({ image, values }) => {
  return (
    <PageSectionWrap>
      <Image>
        <FadeUp>
          <Img src={image} alt="" />
        </FadeUp>
      </Image>

      <PageTitle>
        <FadeUp>Values</FadeUp>
      </PageTitle>

      <List>
        {values.map((item, index) => (
          <li key={index}>
            <FadeUp>
              <span>{item.name[0].text}</span>
              <p>{item.summary[0].text}</p>
              <i>0{index + 1}</i>
            </FadeUp>
          </li>
        ))}
      </List>

      <Actions>
        <Button to="/find-work" title="Find Work"></Button>
        <Button to="/find-staff" title="Find Staff"></Button>
      </Actions>
    </PageSectionWrap>
  )
}
