import React from "react"
import styled from "styled-components"
import { RichText } from "prismic-reactjs"

import { Img, Flex, PageSectionWrap, PageTitle } from "../objects"
import FadeUp from "../fade-in-up"

const Image = styled.div`
  width: 21.942vw;
  height: 27.927vw;
  position: relative;
  z-index: 1;

  figure {
    width: 100%;
    height: 100%;
    background: ${props => props.theme.gold};
  }

  @media (max-width: 767px) {
    margin-top: 18vw;
    width: 75%;
    height: 50vw;

    &:after {
      content: "";
      position: absolute;
      right: -50vw;
      background: ${props => props.theme.black};
      height: 100%;
      width: 100%;
      top: 20%;
      z-index: -1;
    }
  }
`

const Story = styled.section`
  width: 35.703vw;
  margin-left: 12.309vw;

  p {
    padding-bottom: 4.804vw;
    &:last-of-type {
      padding-bottom: 0;
    }
  }

  .portrait & {
    width: 35.703vw;
    margin-left: 10%;
    padding-bottom: 10vw;
  }

  @media (max-width: 767px) {
    margin-left: 0;
    width: 100%;
  }
`

export default ({ byline, title, mission, image }) => {
  return (
    <PageSectionWrap>
      <PageTitle>
        <FadeUp>
          <span>{byline[0].text}</span>
          <span>{title[0].text}</span>
        </FadeUp>
      </PageTitle>

      <Flex justify fluidOnMobile>
        <Story>
          <FadeUp>
            <RichText render={mission} />
          </FadeUp>
        </Story>

        <Image>
          <figure>
            <FadeUp delay={0.75}>
              <Img src={image} alt=""></Img>
            </FadeUp>
          </figure>
        </Image>
      </Flex>
    </PageSectionWrap>
  )
}
