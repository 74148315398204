import React from "react"
import { graphql } from "gatsby"

import Meta from "../utilities/seo"

import Page from "../components/page"
import { PageBkg } from "../components/objects"
import Vision from "../components/agency/vision"
import Values from "../components/agency/values"

export default ({ data }) => {
  let node = data.prismic.allAbouts.edges[0].node

  return (
    <>
      <Meta title="UK Staffing Agency - Switch40" />
      <Page photo={node.cover.url}>
        <PageBkg>
          <Vision
            title={node.title}
            byline={node.byline}
            mission={node.mission}
            image={node.mission_image.url}
          />
          <Values values={node.values} image={node.values_image.url} />
        </PageBkg>
      </Page>
    </>
  )
}

export const query = graphql`
  {
    prismic {
      allAbouts {
        edges {
          node {
            cover
            title
            byline
            mission
            mission_image
            values_image
            values {
              name
              summary
            }
          }
        }
      }
    }
  }
`
